// Variables

$primary-color: #3C6693;
$secondary-color: #70D44B;
$accent-color: #00A0DF;


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-image: url(./images/BG.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"), url("./fonts/Roboto-Black.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 18vh;
  width: 100vw;
  z-index: 0;
  transition: all 0.5s ease-in-out;
  .logos {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 25%;
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15vh;
  width: 100%;
  z-index: 0;
  .logos {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 20%;
    }
  }
  .data {
    font-family: "Roboto-Black";
    color: white;
    font-size: 1rem;
    padding: 0;
  }
}


// Utilities
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex {
  display: flex;
  & .col {
    flex-direction: column;
  }
  & .around {
    justify-content: space-around;
  }
  & .between {
    justify-content: space-between;
  }
  & .center-items {
    align-items: center;
  }
  & .center-content {
    justify-content: center;
  }
}

// Typography 
h1 {
  color: white;
  font-size: 2rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}
h3 {
  color: white;
  font-size: 1.5rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
// UI
button, input[type="submit"], .btn {
  font-size: 1rem;
  line-height: 1rem;
  font-family: "Roboto-Black";
  color: white;
  background-color: #00529b;
  padding: 0.8rem;
  border-radius: 50px;
  border: none;
  margin: 0.5rem;
  cursor: pointer;
}

// Components

// Check List
.check-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 60%;
  width: 70%;
  padding: 0.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  z-index: 2;
  .persons {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 50vh;
    padding: 0.5rem;
    z-index: 3;
    margin: 1rem;
    transition: all 0.5s ease-in-out;
    background-color: white;
    overflow-y: scroll;
    
  }
  .person {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 0;
    border-width: 0 0 1px 0;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    color: $primary-color;
    user-select: none;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    &:has(h2.checked) {
      color: white;
      background-color: $primary-color;
    }
    &:last-of-type {
      border-width: 0;
    }
    h2 {
      font-family: "Roboto-Black";
      font-size: 1.5rem;
      text-align: left;
      line-height: 100%;
      padding: 1rem;
      // text-transform: capitalize;
    }
    .name {
      width: 60%;
    }
    .status {
      width: 10%;
    }
    .time {
      width: 20%;
    }
    &.selected {
      justify-content: space-between;
      background-color: $accent-color;
      color: white;
      border-radius: 2rem;
      padding-right: 0.5rem;
      h2 {
        font-size: 2rem;
      }
    }
  }
  .head {
    display: flex;
    width: 100%;
    /* position: fixed; */
    top: 0;
    left: 15%;
    padding: 1rem 1rem 0 1rem;
    font-size: 1.2rem;
    font-family: "Roboto-Bold";
    text-align: left;
    color: $accent-color;
    .name {
      width: 60%;
    }
    .checked {
      width: 10%;
    }
    .time {
      width: 20%;
    }
  }
  .search-container {
    width: 80%;
  }
  .search {
    display: inline-block;
    width: 70%;
    height: 3rem;
    border-radius: 50px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    background-color: white;
    color: $primary-color;
    font-family: "Roboto";
    font-size: 1.5rem;
    box-shadow: none;
    margin-right: 1.5rem;
    padding: 0 1rem;
    position: relative;
  }
  .search-icon {
    position: relative;
    right: 4rem;
    top: 0.5rem;
    width: 2rem;
    height: 2rem;
    background-image: url("./images/search.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .search-label {
    color: #3C6693;
    width: 20%;
    font-size: 1.5rem;
    font-family: 'Roboto';
    line-height: 3rem;
    margin-right: 1rem;
    text-align: right;
  }
}

// Media Queries
@media only screen and (max-width: 900px) {

  header {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 2rem 0;
    .logos {
      width: 90%;
      img {
        width: 30%;
      }
    }
  }
  footer {
    height: auto;
    padding: 2rem 0;
    .logos {
      width: 90%;
      img {
        width: 20%;
      }
    }
  }
  .check-section {
    width: 100%;
    height: 80%;
    .head {
      font-size: 1rem;
      .checked {
        width: 25%;
      }
    }
    .persons {
      height: 60vh;
    }
    .person {
      h2 {
        font-size: 1rem;
      }
    }
    .search-container {
      width: 90%;
    }
  }
  
}